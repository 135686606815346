import '../App.css';
import React, {Component} from 'react';
import {homeFixOn, homeFixOff} from '../util.js';
import reallogo from '../images/newreal.png';
import background from '../images/breedsmabackground3.png'

export default class Home extends Component {

    render() {
        return <>
        <div className='homecontainer'>
        
        <br></br><br></br><br></br><br></br>
        <div className='centersquare'>
            <a href="/real"><img id="reallogo" src={reallogo} Alt="REAL"></img></a>
        </div>

        </div>
        </>
    }
}