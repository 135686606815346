import instagram from '../images/instagram.png'
import breedsma from '../images/bBreedsmaLogo.png'
import real from '../images/realbutton.png'
import elysia from '../images/elysia.jpg'
import '../about.css';



export default function About(){
    return(
        <>
     <div style={{display:'flex',padding:'20px', position:'relative'}}>
         
         

     </div>
     <div className='aboutHeader'>  <h1>About Breedsma </h1> </div>
     <div className='aboutsmallimageholders'><img src={breedsma} alt="breedsma" className='aboutimagesmall'></img>  </div>
        <div className='aboutblurbholder'>
        <div className='aboutblurb'> <p>Breedsma is an artistic label used for my creations since 2018, primarily in the world of jewellery.</p>  </div>
        <div className='aboutblurb'> <p>Beginning with rings made from tools laying around in my basement, pieces are now created in my small studio by utilizing bespoke manufacturing processes as well as traditional techniques.</p></div>
        <div className='aboutblurb'> <p>My debut collection <a href="/real" className='rainbowareal'>“REAL”</a> finally released in "October of 2023", after 5 years of work. <a href="/real" className='rainbowareal'>REAL</a> is a collection of ring designs meant to explore the uses of polymers and alternative manufacturing methods in jewellery, and introduces more saturated and vibrant colours to the often unchanging medium.</p></div>
        <div className='aboutblurb'> <p><a href="/real" className='rainbowareal'>REAL</a> is a collection of rings that incorporates design elements from various other fields that in my eyes are universally beautiful. You can see this through the unorthodox shapes, saturated colours, and unusual materials used including wood, polymers, and powder coated metal. </p></div>
        <div className='aboutblurb'> <p>The execution of <a href="/real" className='rainbowareal'>REAL</a> is designed to explore new ideas for how a luxurious or premium product is presented. The packaging juxtaposes a high-quality, handmade item in a form factor that is typically seen with collectables or toys. The graphics have been extremely carefully designed with hundreds of revisions in order to provide a visually coherent experience that retains a sense of vibrancy and life.</p></div>
        <div className='aboutblurb'> <p>A metaphor that I think describes the collection well is that <a href="/real" className='rainbowareal'>REAL</a> is designed to feel like a collectable item you could purchase at a vending machine in an ethereal, alternate future.</p></div>
        <div className='aboutblurb'><p>Looking forward, my upcoming collection <a href="/elysia" className='rainbowaelysia'>Elysia</a> is designed to be the peak of luxury in that same future. Retaining design characteristics that are distinctly Breedsma, the packaging and presentation will explore the boundaries of the traditional luxury experience, both in physical sensations and in customer experience.</p></div>
        </div> 
        <div className='aboutimageholder'>
        <img src={breedsma} alt="breedsma" className='aboutimage'></img>  
        </div> 

        <div className='aboutlinksbox'>
        <div><a href="https://www.instagram.com/breedsma/?hl=en"><img className='abouticonlink' src={instagram} alt="instagram" style={{boxShadow:'0 0 rgba(0,0,0,0)'}} ></img></a></div>
        <div><a href="/real"><img className='abouticonlink' src={real} alt="real"  ></img></a></div>
        <div><a href="/elysia"><img className='abouticonlink' src={elysia} alt="elysia"  ></img></a></div>

        </div>

       
  


        </>
    )
}