import React, {Component} from 'react'
import Signup from '../components/SignUp'
import { auth, handleUserProfile } from '../firebase/utils'; // Import handleUserProfile from the correct location
import LoadingSpinner from '../components/loadingSpinner';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.authListener = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await handleUserProfile(userAuth);
        userRef.onSnapshot((snapshot) => {
          this.setState({
            loading: false, // Once user data is loaded, set loading to false
          });
        });
      } else {
        this.setState({
          loading: false, // If no user is authenticated, set loading to false
        });
      }
    });
  }

  componentWillUnmount() {
    this.authListener();
  }
  
    render() {
      const { loading } = this.state;
      const { currentUser } = this.props;
  
      // Show a loading state while waiting for currentUser
      if (loading) {
        return <div className="centersquare"><LoadingSpinner /></div>;
      }
  
      // Render the content once the user data is available
      return (
        <>
          {currentUser ? (
            <>
              <div></div>
            </>
          ) : (
            <>
              <div className="centersquare">
              <Signup />
              </div>
            </>
          )}
        </>
      );
    }
  }
  
  export default Register;