import React, { useState } from 'react';
import './account.css';
import Button from './../forms/Button';
import { signInWithGoogle, auth} from '../../firebase/utils';
import { useNavigate } from 'react-router-dom';
import FormInput from '../forms/FormInput';
import Google from '../../images/google.png'

const initialState = {
  email: '',
  password: '',
};

const SignIn = () => {
  const [formData, setFormData] = useState(initialState); // Use the useState hook for form data
  const navigate = useNavigate(); // Use the useNavigate hook inside the functional component

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData ;
    try{
      await auth.signInWithEmailAndPassword(email,password);
      this.setState({
        ...initialState
      })
    }catch(err){
      //console.log(err)
    }
  };

  const handleRegisterClick = () => {
    // Redirect the user to the /register page
    navigate('/register');
  };

  const { email, password } = formData;

  return (
    <div className="signin">
      <div className="wrap">
        <br />
        <h2 className="loginbutton">login to breedsma</h2>

        <div className="formWrap">
          <form onSubmit={handleSubmit}>
            <div className="socialSignin">
              <div className="rowCenter">
              <FormInput
                className="formInput centerForm"
                type="email"
                name="email"
                value={email}
                placeholder="Email"
                handleChange={handleChange}
              />
              </div>
              <div className="rowCenter">
              <FormInput
                className="formInput centerForm"
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                handleChange={handleChange}
              />
              </div>

              <Button type="submit" style={{marginTop:'10px', marginBottom:'10px', border:'2px solid black'}}>LogIn</Button>
              

              
                <img onClick={signInWithGoogle} className="googleSignIn" src={Google} alt="Google"></img>
              <br />
              <h2 className="loginbutton" style={{marginBottom:'20px'}}>Don't have an account?</h2>
              <br />
              <div className="row">
                <Button onClick={handleRegisterClick} style={{border:"2px solid black"}}>Register</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
