import real from '../images/realbutton.png'
import elysia from '../images/elysia.jpg'
import '../collections.css';

export default function Collections(){
    return(
        <>
            <h1 className='collectionHeader' style={{textAlign:'center'}}>Collections</h1>
            <div style={{textAlign:'center', display:'flex', gap:'40px', justifyContent:'center', padding:'40px'}}>
            <a href="/real"><img className="collectionButton" src={real} alt="Real"></img></a>
            <a href="/elysia"><img className="collectionButton" src={elysia} alt="Elysia"></img></a>
            </div>
        </>
    )
}