export default function LatestWork(){
    return(
        <>
        <h1>Latest Work</h1>
        <p>Whats Up Breedsmsa Fans, Check out what I've been on</p>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/real">Real</a></li>
        </ul>
        </>
    )
}