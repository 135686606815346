import React, { Component } from 'react';
import SignIn from '../components/SignIn';
import { auth, handleUserProfile } from '../firebase/utils'; // Import handleUserProfile from the correct location
import LoadingSpinner from '../components/loadingSpinner';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.authListener = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await handleUserProfile(userAuth);
        userRef.onSnapshot((snapshot) => {
          this.setState({
            loading: false, // Once user data is loaded, set loading to false
          });
        });
      } else {
        this.setState({
          loading: false, // If no user is authenticated, set loading to false
        });
      }
    });
  }

  componentWillUnmount() {
    this.authListener();
  }

  render() {
    const { loading } = this.state;
    const { currentUser } = this.props;

    // Show a loading state while waiting for currentUser
    if (loading) {
      return <div className="centersquare"><LoadingSpinner /></div>;
    }

    // Render the content once the user data is available
    return (
      <>
        {currentUser ? (
          <>
            <h1 style={{ textAlign: 'center' }}>Logged into BREEDSMA.COM</h1>
            <h3 style={{ textAlign: 'center' }}>POWER UP™ with BREEDSMA™</h3>
            <button className="center" onClick={() => auth.signOut()}>
              Logout
            </button>
          </>
        ) : (
          <>
            <br />
            <br />
            <br />
            <SignIn />
          </>
        )}
      </>
    );
  }
}

export default Account;
