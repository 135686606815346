import React, {Component} from 'react';
import { Routes, Route, Navigate} from 'react-router-dom';
import { auth, handleUserProfile } from './firebase/utils'
//layouts

//pages
import TopBar from './pages/Topbar';
import Menu from './pages/Menu';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';
import Collections from './pages/Collections';
import Account from './pages/Account';
import LatestWork from './pages/LatestWork';
import Real from './pages/Real';
import Elysia from './pages/Elysia';
import Bag from './pages/Bag';
import Store from './pages/Store';
import Test from './pages/Test';
import Register from './pages/register';
//css
import './App.css';

const initialState= {
  currentUser: null
}

class App extends Component {
  constructor(props){
    super(props);
    this.state={
      ...initialState
    };
  }

  authListener = null;

  componentDidMount() {
    this.authListener = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await handleUserProfile(userAuth);
        userRef.onSnapshot((snapshot) => {
          this.setState({
            currentUser: {
              id: snapshot.id,
              ...snapshot.data(),
            },
          });
        });
      } else {
        this.setState({
          currentUser: null, // Set the currentUser to null when no user is authenticated
        });
      }
    });
  }

  
  componentWillUnmount(){
    this.authListener();
  }

  render(){
    const { currentUser } = this.state;

    return (
      <div className="App">
          <TopBar />
        <div className="content">
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/about" element={<About />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/portfolio" element={<Portfolio />}></Route>
              <Route path="/collections" element={<Collections />}></Route>
              <Route path="/account" element={<Account currentUser={currentUser} />} />
              <Route path="/latestwork" element={<LatestWork />}></Route>
              <Route path="/real" element={<Real />}></Route>
              <Route path="/elysia" element={<Elysia />}></Route>
              <Route path="/store" element={<Store />}></Route>
              <Route path="/bag" element={<Bag />}></Route>
              <Route path="/test" element={<Test />}></Route>        
              <Route path="/register" element={currentUser ? (
                  <Navigate to="/account" replace />) : (
                  <Register currentUser={currentUser} />)}/>

            </Routes>
          </div>
          <Menu />
      </div>
    );
  }
  
}

export default App;