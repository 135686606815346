import React, {Component} from 'react';
import './signup.css';

import {auth, handleUserProfile } from './../../firebase/utils';

import FormInput from '../forms/FormInput';
import Button from '../forms/Button';

const initialState = {
  displayName: '',
  email: '',
  password: '',
  confirmPassword: '',
  errors: []
};

class Signup extends Component {
    constructor(props){
        super(props);
        this.state={
            ...initialState
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const {name, value} = e.target;

        this.setState({
            [name]: value
        });
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        const { displayName, email, password, confirmPassword, errors } = this.state;
      
        if (password !== confirmPassword) {
          const err = ["Password Doesn't Match!"];
          this.setState({
            errors: err,
          });
          return;
        }
      
        try {
          console.log("Before create user");
          const { user } = await auth.createUserWithEmailAndPassword(email, password);
          console.log("After create user");
          await handleUserProfile(user, { displayName });
          console.log("After handleUserProfile");
      
          this.setState({
            ...initialState,
          });
        } catch (err) {
          console.log("Error:", err);
          // Check if the error is from Firebase
          if (err.code === "auth/weak-password") {
            // Handle the weak password error
            const errMsg = ["Password is too weak. Please choose a stronger password. Need at least 6 characters"];
            this.setState({ errors: errMsg });
          } else {
            // Handle other Firebase errors
            const errMsg = ["Cannot create account under this email"];
            this.setState({ errors: errMsg });
          }
        }
      };
    render(){
        const { displayName , email, password, confirmPassword, errors} = this.state;
        return(
            <div className="signup">
                <div className="wrap">
                <h2 className="signupheader">Sign Up For Breedsma</h2>
                <p style={{textAlign:'center'}}>Track your collection and the latest news from Breedsma</p>
                </div>

                <form onSubmit={this.handleFormSubmit} style={{margin:'0 auto', width:'160px', marginBottom:'10px'}}>
                    <FormInput
                        type="text"
                        name="displayName"
                        value={displayName}
                        placeholder="Display Name"
                        onChange={this.handleChange}
                    />
                    <FormInput
                        type="text"
                        name="email"
                        value={email}
                        placeholder="Email"
                        onChange={this.handleChange}
                    />
                    <FormInput
                        type="password"
                        name="password"
                        value={password}
                        placeholder="Password"
                        onChange={this.handleChange}
                    />
                    <FormInput
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        onChange={this.handleChange}
                    />
                    <button type="submit" className="btn" style={{marginTop:'10px', border:'2px black solid'}}> 
                        Register
                    </button>
                </form>
                
                {errors.length > 0 && (
                    <ul className="registerErrorsList">
                        {errors.map((err, index) => {
                            return (
                                <li key={index}>
                                {err}
                            </li>
                            );
                        })}
                    </ul>
                )}

            </div>
            
        )
    }
}

export default Signup;


