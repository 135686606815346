import '../navbar.css';
import breedsmalogo from '../images/2fullBreedsmaLogoSmallFinalWhite.png'

export default function TopBar(props) {
  return <div className='topbar'>
    <>     
        <a href="/">
        <img className='breedsmalogo' src={breedsmalogo} Alt="Breedsma"></img></a>
           </>
        </div>
}