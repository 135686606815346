
import '../navbar.css';
import collections from '../menuicons/collections.png'
import about from '../menuicons/about.png'
import bag from '../menuicons/bag.png'
import contact from '../menuicons/contact.png'
import portfolio from '../menuicons/portfolio.png'
import store from '../menuicons/store.png'
import account from '../menuicons/account.png'

export default function Menu(props) {
  
  return  <><div className="navbardiv"> 
              <div className='iconbox'><a className='hyperlinkicon' href="/collections" ><img className="icon" src={collections} Alt="Collections"></img></a></div>
              <div className='iconbox'><a className='hyperlinkicon' href="/portfolio"><img className="icon" src={portfolio} Alt="Portfolio"></img></a></div>          
              <div className='iconbox'><a className='hyperlinkicon' href="/about"><img className="icon" src={about} Alt="About"></img></a></div>           
              <div className='iconbox'><a className='hyperlinkicon' href="/contact"><img className="icon" src={contact} Alt="Contact"></img></a></div>          
              <div className='iconbox'><a className='hyperlinkicon' href="/store"><img className="icon" src={store} Alt="Store"></img></a></div>          
              <div className='iconbox'><a className='hyperlinkicon' href="/bag"><img className="icon" src={bag} Alt="Bag"></img></a></div>           
              <div className='iconbox'><a className='hyperlinkicon' href="/account"><img className="icon" src={account} Alt="Account"></img></a></div>           
           </div>
           <div className="underbar">
           <div className='hide icon'><p>Collections</p></div>
           <div className='hide icon'><p>Portfolio</p></div>
           <div className='hide icon'><p>About</p></div>
           <div className='hide icon'><p>Contact</p></div>
           <div className='hide icon'><p>Store</p></div>
           <div className='hide icon'><p>Bag</p></div>
           <div className='hide icon'><p>Account</p></div>
           </div>
           </>
}