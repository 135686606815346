import reallogo from '../images/realbutton.png';


export default function Test(){
    return(
        <>

<div style={{width: '100%', height: '15%', backgroundColor: 'red'}}> <a href="/real"><img id="reallogo" src={reallogo} Alt="REAL"></img></a>some content</div>
<div style={{width: '100%', height: '10%', backgroundColor: 'yellow'}}><a href="/real"><img id="reallogo" src={reallogo} Alt="REAL"></img></a>some items</div>
<div style={{width: '70%', height: '65%', backgroundColor: 'gray'}}><a href="/real"><img id="reallogo" src={reallogo} Alt="REAL"></img></a>some items</div>
<div style={{width: '100%', height: '10%', backgroundColor: 'green'}}><a href="/real"><img id="reallogo" src={reallogo} Alt="REAL"></img></a>some items</div>

        </>
    )
}

 